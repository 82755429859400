import Vue from 'vue'
import VueRouter from 'vue-router'
import ReportList from "@/views/ReportList";

Vue.use(VueRouter)

const routes = [
  {
    path: '/vue/report/agree',
    name: 'HomeView',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportAgree.vue'),
    meta: {
      title: '注册条款及协议'
    }
  },
  {
    path: '/',
    name: 'ReportSearch',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportSearch.vue'),
    meta: {
      title: '报告查询'
    }
  },
  {
    path: '/vue/report/list',
    name: 'ReportList',
    component: ReportList,
    meta: {
      title: '我的报告'
    }
  },
  {
    path: '/vue/report/search',
    name: 'ReportSearch',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportSearch.vue'),
    meta: {
      title: '报告查询'
    }
  },
  {
    path: '/vue/my/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      title: '用户登录'
    }
  },
  {
    path: '/vue/my/bind/:bindId',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/UserBind.vue'),
    meta: {
      title: '用户绑定'
    }
  },
  {
    path: '/vue/my',
    name: 'My',
    component: () => import(/* webpackChunkName: "about" */ '../views/My.vue'),
    meta: {
      title: '个人中心'
    }
  },
  {
    path: '/vue/my/QrCode',
    name: 'My',
    component: () => import(/* webpackChunkName: "about" */ '../views/QrCode.vue'),
    meta: {
      title: '个人中心'
    }
  },
  {
    path: '/vue/report/bind',
    name: 'ReportBind',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportBind.vue'),
    meta: {
      title: '报告绑定'
    }
  },
  {
    path: '/vue/report/sampleId',
    name: 'ReportBindSampleId',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportBindSampleId.vue'),
    meta: {
      title: '报告绑定'
    }
  },
  {
    path: '/vue/report/caiyang',
    name: 'ReportBindCaiYang',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportBindCaiYang.vue'),
    meta: {
      title: '报告绑定'
    }
  },
  {
    path: '/vue/report/address',
    name: 'ReportBindAddress',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportBindAddress.vue'),
    meta: {
      title: '报告绑定'
    }
  },
  {
    path: '/vue/report/finished',
    name: 'ReportBindFinished',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportBindFinished.vue'),
    meta: {
      title: '报告绑定'
    }
  },
  {
    path: '/vue/report/alert',
    name: 'ReportAlert',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportAlert.vue'),
    meta: {
      title: '报告查询说明'
    }
  },
  {
    path: '/vue/report/expired',
    name: 'ReportExpiredAlert',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportExpiredAlert.vue'),
    meta: {
      title: '报告已过期'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
